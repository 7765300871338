<template>
  <header>
    <nav class="navbar navbar-expand-xl">
      <div class="container">
        <router-link @click="hidden = !hidden" class="navbar-brand" to="/">
          <img src="@/assets/img/all/logo.svg" alt="ARDA Kft." />
          <h1 class="d-none">ARDA.hu</h1>
        </router-link>

        <button
          class="navbar-toggler collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>

        <div
          class="collapse navbar-collapse"
          data-bs-target="collapse"
          id="navbarSupportedContent"
        >
          <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
            <li class="nav-item nav-home">
              <router-link @click="hidden = !hidden" class="nav-link" to="/">
                <span>Főoldal</span>
              </router-link>
            </li>
            <li class="nav-item nav-services">
              <router-link
                @click="hidden = !hidden"
                class="nav-link"
                to="/szolgaltatasaink"
              >
                <span>Szolgáltatásaink</span>
              </router-link>
            </li>

            <li class="nav-item nav-products">
              <router-link
                @click="hidden = !hidden"
                class="nav-link"
                to="/termekeink"
              >
                <span>Termékeink</span>
              </router-link>
            </li>
            <!--<li class="nav-item nav-blog">
              <router-link
                @click="hidden = !hidden"
                class="nav-link"
                to="/blog"
              >
                <span>Blog</span>
              </router-link>
            </li>-->
            <li class="nav-item nav-about">
              <router-link
                @click="hidden = !hidden"
                class="nav-link"
                to="/rolunk"
              >
                <span>Rólunk</span>
              </router-link>
            </li>
          </ul>
          <div class="d-flex d-hello justify-content-center mb-5 mb-xl-0">
            <router-link
              @click="hidden = !hidden"
              class="btn mt-2"
              to="/kapcsolat"
            >
              <span class="d-block d-xl-none d-xxl-block ps-3 pe-3">
                Kezdjük el a közös munkát most!
              </span>
              <span
                class="d-none d-xl-block d-xxl-none ps-3 ps-lg-2 pe-3 pe-lg-2"
              >
                Dolgozzunk együtt!
              </span>
            </router-link>
          </div>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  methods: {
    handleSCroll() {
      const header = document.querySelector(".navbar");
      const footer = document.querySelector(".back-to-top");
      if (window.scrollY > 100 && !header.className.includes("fixed-top")) {
        header.classList.add("fixed-top");
      } else if (window.scrollY < 60) {
        header.classList.remove("fixed-top");
      }

      if (window.scrollY > 600 && !footer.className.includes("fixed-bottom")) {
        footer.classList.add("fixed-bottom");
      } else if (window.scrollY < 500) {
        footer.classList.remove("fixed-bottom");
      }
    },
  },

  created() {
    window.addEventListener("scroll", this.handleSCroll);
  },

  unmounted() {
    window.removeEventListener("scroll", this.handleSCroll);
  },
};
</script>
