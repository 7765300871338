<template>
  <!--<NewsLine />-->

  <div v-if="cookies.get('cookiesAcceptWarning') != true" id="cookie-warning">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-8">
          <h5>Cookie-k engedélyezése</h5>
          <p class="lead">
            Ez a weboldal cookie-kat használ az online felhasználói élmény
            növelése érdekében. Ön az oldal használatának folytatásával
            beleegyezik a cookie-k használatába.
          </p>
          <p class="lead">
            <router-link
              class="btn btn-link"
              to="/adatvedelmi-tajekoztato/"
              @click="hidden = !hidden"
            >
              <span>További információk</span>
            </router-link>
          </p>
        </div>
        <div class="col-12 col-lg-4">
          <div
            class="d-lg-flex justify-content-center align-items-center align-self-center text-center h-100"
          >
            <button
              class="btn d-block ps-3 pe-3 mt-3 mt-lg-0"
              @click="cookies.set('cookiesAcceptWarning', 'true')"
            >
              <span class="ps-4 pe-4">elfogadom</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Header />
  <router-view v-slot="{ Component, route }">
    <transition :name="route.meta.transition || 'fade'">
      <component :is="Component" />
    </transition>
  </router-view>
  <Footer />
</template>

<script>
// @ is an alias to /src
// import NewsLine from '@/components/NewsLine.vue';
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import { useCookies } from "@vueuse/integrations";

export default {
  setup() {
    const cookies = useCookies();
    return {
      cookies,
    };
  },

  components: {
    // NewsLine,
    Header,
    Footer,
  },
};
</script>
