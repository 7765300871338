<template>
  <footer>
    <section class="section section-footer-navigation">
      <div class="container-xl">
        <div class="row">
          <div class="col-12 col-lg-4">
            <router-link @click="hidden = !hidden" class="navbar-brand" to="/">
              <img
                src="@/assets/img/all/logo-footer.svg"
                class="logo-footer"
                alt="ARDA Kft."
              />
              <h1 class="d-none">ARDA.hu</h1>
            </router-link>
            <ul class="list-unstyled">
              <li><router-link to="/"> Főoldal </router-link></li>
              <li><router-link to="/rolunk"> Rólunk </router-link></li>
              <li><router-link to="/blog"> Blog </router-link></li>
              <!--<li>
                <router-link to="/referenciak"> Referenciák </router-link>
              </li>-->
              <li>
                <router-link to="/adatvedelmi-tajekoztato">
                  Adatvédelmi tájékoztató
                </router-link>
              </li>
              <!--<li><router-link to="/karrier"> Karrier </router-link></li>-->
              <li><router-link to="/kapcsolat"> Kapcsolat </router-link></li>
            </ul>
          </div>
          <div class="col-12 col-lg-4">
            <h6>
              <router-link to="/szolgaltatasaink">
                Szolgáltatásaink
              </router-link>
            </h6>
            <ul class="list-unstyled">
              <li>
                <router-link to="/szolgaltatasaink/landing-oldal-keszites">
                  Landing oldal
                </router-link>
              </li>
              <li>
                <router-link
                  to="/szolgaltatasaink/statikus-es-dinamikus-banner-keszites"
                >
                  Statikus és dinamikus bannerek
                </router-link>
              </li>
              <li>
                <router-link to="/szolgaltatasaink/support-szolgaltatas">
                  Support szolgáltatás
                </router-link>
              </li>
              <!--<li>
                <router-link to="/szolgaltatasaink/sitebuild">
                  Sitebuild
                </router-link>
              </li>
              <li>
                <router-link to="/szolgaltatasaink/frontend-fejlesztes">
                  Frontend fejlesztés
                </router-link>
              </li>
              <li>
                <router-link to="/szolgaltatasaink/magento-webaruhaz">
                  Magento webáruház
                </router-link>
              </li>-->
            </ul>
          </div>
          <div class="col-12 col-lg-4">
            <h6>
              <router-link to="/termekeink"> Termékeink </router-link>
            </h6>
            <ul class="list-unstyled">
              <li>
                <router-link to="/termekeink/magento-2-magyar-nyelvi-modul">
                  Magento 2.x magyar nyelvi modul
                </router-link>
              </li>
              <li>
                <router-link to="/termekeink/magento-1-magyar-nyelvi-modul">
                  Magento 1.x magyar nyelvi modul
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </footer>

  <section class="section section-footer-copyright">
    <div class="container">
      <div class="row">
        <div class="col-12 pt-3 pb-3 text-center">
          &copy; 2002-{{ new Date().getFullYear() }}. ARDA Kft.
        </div>
      </div>
    </div>
  </section>

  <a href="#top" class="back-to-top">
    <span class="d-none">Back to Top</span>
  </a>
</template>
