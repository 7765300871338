import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      transition: "slide-right",
      title: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
        { property: "og:site_name", content: "ARDA Kft." },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft..",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Landing oldal, Weblap és Banner készítés - ARDA Kft.",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "home" */ "../views/Home.vue"),
  },
  {
    path: "/szolgaltatasaink",
    name: "services",
    meta: {
      transition: "slide-right",
      title: "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        {
          property: "og:description",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        {
          itemprop: "description",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content:
            "Landing oldal, Weblap és banner szolgáltatásaink - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "services" */ "../views/Services.vue"),
  },
  {
    path: "/szolgaltatasaink/landing-oldal-keszites",
    name: "serviceslandingoldalkeszites",
    meta: {
      transition: "slide-right",
      title: "Landing oldal készítés - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Landing oldal készítés - ARDA Kft.",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Landing oldal készítés - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Landing oldal készítés - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Landing oldal készítés - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "serviceslandingoldalkeszites" */ "../views/szolgaltatasaink/ServicesLandingoldalkeszites"
      ),
  },
  {
    path: "/szolgaltatasaink/sitebuild",
    name: "servicessitebuild",
    meta: {
      transition: "slide-right",
      title: "Sitebuild - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Sitebuild - ARDA Kft.",
          content: "Sitebuild - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Sitebuild - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Sitebuild - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Sitebuild - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Sitebuild - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Sitebuild - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Sitebuild - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Sitebuild - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Sitebuild - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Sitebuild - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "servicessitebuild" */ "../views/szolgaltatasaink/ServicesSitebuild"
      ),
  },
  {
    path: "/szolgaltatasaink/statikus-es-dinamikus-banner-keszites",
    name: "servicesstatikusesdinamikusbannerkeszites",
    meta: {
      transition: "slide-right",
      title: "Statikus és dinamikus banner készítés - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Statikus és dinamikus banner készítés - ARDA Kft.",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Statikus és dinamikus banner készítés - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "servicesstatikusesdinamikusbannerkeszites" */ "../views/szolgaltatasaink/ServicesStatikusesdinamikusbannerkeszites"
      ),
  },
  {
    path: "/szolgaltatasaink/support-szolgaltatas",
    name: "servicessupportszolgaltatas",
    meta: {
      transition: "slide-right",
      title: "Support szolgáltatás - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Support szolgáltatás - ARDA Kft.",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Support szolgáltatás - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Support szolgáltatás - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Support szolgáltatás - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "servicessupportszolgaltatas" */ "../views/szolgaltatasaink/ServicesSupportSzolgaltatas"
      ),
  },
  {
    path: "/termekeink",
    name: "products",
    meta: {
      transition: "slide-right",
      title: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Magento 2.x és 1.x magyar nyelvi csomag - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
  },
  {
    path: "/termekeink/magento-2-magyar-nyelvi-modul",
    name: "productsmagento2magyarnyelvimodul",
    meta: {
      transition: "slide-right",
      title: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Magento 2.x magyar nyelvi csomag - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "productsmagento2magyarnyelvimodul" */ "../views/termekeink/ProductsMagento2x.vue"
      ),
  },
  {
    path: "/termekeink/magento-1-magyar-nyelvi-modul",
    name: "productsmagento1magyarnyelvimodul",
    meta: {
      transition: "slide-right",
      title: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Magento 1.x magyar nyelvi csomag - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(
        /* webpackChunkName: "productsmagento1magyarnyelvimodul" */ "../views/termekeink/ProductsMagento1x.vue"
      ),
  },
  {
    path: "/blog",
    name: "blog",
    meta: {
      transition: "slide-right",
      title: "Blog - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Blog - ARDA Kft.",
          content: "Blog - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Blog - ARDA Kft.",
        },
        { property: "og:site_name", content: "Blog - ARDA Kft." },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        { property: "og:image:alt", content: "Blog - ARDA Kft." },
        {
          property: "og:description",
          content: "Blog - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Blog - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Blog - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Blog - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Blog - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Blog - ARDA Kft.",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue"),
  },
  {
    path: "/rolunk",
    name: "about",
    meta: {
      transition: "slide-right",
      title: "Rólunk - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Rólunk - ARDA Kft.",
          content: "Rólunk - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Rólunk - ARDA Kft.",
        },
        { property: "og:site_name", content: "Rólunk - ARDA Kft." },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        { property: "og:image:alt", content: "Rólunk - ARDA Kft." },
        {
          property: "og:description",
          content: "Rólunk - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Rólunk - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Rólunk - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Rólunk - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Rólunk - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Rólunk - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/kapcsolat",
    name: "contact",
    meta: {
      transition: "slide-right",
      title: "Kapcsolat - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Kapcsolat - ARDA Kft.",
          content: "Kapcsolat - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Kapcsolat - ARDA Kft.",
        },
        { property: "og:site_name", content: "Kapcsolat - ARDA Kft." },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        { property: "og:image:alt", content: "Kapcsolat - ARDA Kft." },
        {
          property: "og:description",
          content: "Kapcsolat - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Kapcsolat - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Kapcsolat - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Kapcsolat - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Kapcsolat - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Kapcsolat - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/adatvedelmi-tajekoztato",
    name: "privacy",
    meta: {
      transition: "slide-right",
      title: "Adatvédelmi tájékoztató - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          title: "Adatvédelmi tájékoztató - ARDA Kft.",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        {
          property: "og:site_name",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          property: "og:image:alt",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        {
          property: "og:description",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/" },
        {
          name: "twitter:title",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        {
          itemprop: "name",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        {
          itemprop: "description",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Adatvédelmi tájékoztató - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "privacy" */ "../views/Privacy.vue"),
  },
  {
    // path: '/:catchAll(.*)',
    path: "/:pathMatch(.*)*",
    name: "error404",
    meta: {
      title: "Az oldal nem található... - ARDA Kft.",
      metaTags: [
        {
          name: "description",
          content: "Az oldal nem található... - ARDA Kft.",
        },
        // OpenGraph data (Most widely used)
        {
          property: "og:title",
          content: "Az oldal nem található... - ARDA Kft.",
        },
        { property: "og:site_name", content: "ARDA Kft." },
        { property: "og:type", content: "website" },
        { property: "og:url", content: "https://arda.hu/404" },
        {
          property: "og:image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        { property: "og:image:alt", content: "ARDA Kft." },
        {
          property: "og:description",
          content: "Az oldal nem található... - ARDA Kft.",
        },

        // Twitter card
        { name: "twitter:card", content: "summary" },
        { name: "twitter:site", content: "https://arda.hu/404" },
        {
          name: "twitter:title",
          content: "Az oldal nem található... - ARDA Kft.",
        },
        {
          name: "twitter:description",
          content: "Az oldal nem található... - ARDA Kft.",
        },
        {
          name: "twitter:image:src",
          content: "https://arda.hu/api/facebook-cover.png",
        },

        // Google / Schema.org markup:
        { itemprop: "name", content: "Az oldal nem található... - ARDA Kft." },
        {
          itemprop: "description",
          content: "Az oldal nem található... - ARDA Kft.",
        },
        {
          itemprop: "image",
          content: "https://arda.hu/api/facebook-cover.png",
        },
        {
          name: "keywords",
          content: "Az oldal nem található... - ARDA Kft.",
        },
      ],
    },
    component: () =>
      import(/* webpackChunkName: "error404" */ "../views/404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  behavior: "smooth",
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0 };
  },
  routes,
});

// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(
    document.querySelectorAll("[data-vue-router-controlled]")
  ).map((el) => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      // console.log(tag)

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

export default router;
